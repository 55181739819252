import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { CardModule } from 'primeng/card'
import { MenubarModule } from 'primeng/menubar'
import { ButtonModule } from 'primeng/button'
import { DividerModule } from 'primeng/divider'
import { InputTextModule } from 'primeng/inputtext'
import { RadioButtonModule } from 'primeng/radiobutton'
import { AutoCompleteModule } from 'primeng/autocomplete'
import { ProgressSpinnerModule } from 'primeng/progressspinner'
import { DialogModule } from 'primeng/dialog'
import { DragDropModule } from 'primeng/dragdrop'
import { InputSwitchModule } from 'primeng/inputswitch'
import { TieredMenuModule } from 'primeng/tieredmenu'
import { BadgeModule } from 'primeng/badge'
import { DockModule } from 'primeng/dock'
import { MenuModule } from 'primeng/menu'
import { SidebarModule } from 'primeng/sidebar'
import { CheckboxModule } from 'primeng/checkbox'
import { InputTextareaModule } from 'primeng/inputtextarea'
import { TabViewModule } from 'primeng/tabview'
import { ConfirmationService, MessageService } from 'primeng/api'
import { ToastModule } from 'primeng/toast'
import { BreadcrumbModule } from 'primeng/breadcrumb'
import { OverlayPanelModule } from 'primeng/overlaypanel'
import { OverlayModule } from 'primeng/overlay'
import { ScrollTopModule } from 'primeng/scrolltop'
import { PanelMenuModule } from 'primeng/panelmenu'
import { PasswordModule } from 'primeng/password'
import { InputOtpModule } from 'primeng/inputotp'
import { InputMaskModule } from 'primeng/inputmask'
import { DropdownModule } from 'primeng/dropdown'
import { AutoFocusModule } from 'primeng/autofocus'
import { SplitterModule } from 'primeng/splitter'
import { TableModule } from 'primeng/table';
import { AccordionModule } from 'primeng/accordion';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { PanelModule } from 'primeng/panel';
import { InputNumberModule } from 'primeng/inputnumber'
import { MessagesModule } from 'primeng/messages';
import { CarouselModule } from 'primeng/carousel';
import { KeyFilterModule } from 'primeng/keyfilter';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    CardModule,
    MenubarModule,
    ButtonModule,
    DividerModule,
    InputTextModule,
    RadioButtonModule,
    AutoCompleteModule,
    ProgressSpinnerModule,
    DialogModule,
    DragDropModule,
    InputSwitchModule,
    TieredMenuModule,
    BadgeModule,
    DockModule,
    MenuModule,
    SidebarModule,
    CheckboxModule,
    InputTextareaModule,
    TabViewModule,
    ToastModule,
    BreadcrumbModule,
    OverlayPanelModule,
    ScrollTopModule,
    PanelMenuModule,
    PasswordModule,
    InputOtpModule,
    InputMaskModule,
    DropdownModule,
    SplitterModule,
    TableModule,
    AccordionModule,
    IconFieldModule,
    InputIconModule,
    InputNumberModule,
    OverlayModule,
    ScrollPanelModule,
    ConfirmDialogModule,
    MessagesModule,
    PanelModule,
    CarouselModule,
    KeyFilterModule
  ],
  exports: [
    CardModule,
    MenubarModule,
    ButtonModule,
    DividerModule,
    InputTextModule,
    RadioButtonModule,
    AutoCompleteModule,
    ProgressSpinnerModule,
    DialogModule,
    DragDropModule,
    InputSwitchModule,
    TieredMenuModule,
    BadgeModule,
    DockModule,
    MenuModule,
    SidebarModule,
    CheckboxModule,
    InputTextareaModule,
    TabViewModule,
    ToastModule,
    BreadcrumbModule,
    OverlayPanelModule,
    ScrollTopModule,
    PanelMenuModule,
    PasswordModule,
    InputOtpModule,
    InputMaskModule,
    DropdownModule,
    AutoFocusModule,
    SplitterModule,
    TableModule,
    AccordionModule,
    IconFieldModule,
    InputIconModule,
    InputNumberModule,
    OverlayModule,
    ScrollPanelModule,
    ConfirmDialogModule,
    MessagesModule,
    PanelModule,
    CarouselModule,
    KeyFilterModule
  ],
  providers: [MessageService, ConfirmationService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PrimeNGModule { }
