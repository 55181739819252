import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phone'
})
export class PhoneFormatPipe implements PipeTransform {

  transform(value: string): string {
    if (!value) return value;

    const phoneNumber = value.replace(/\D/g, '');

    if (phoneNumber.length !== 10) {
      return value
    }

    return `${phoneNumber.substring(0, 3)}-${phoneNumber.substring(3, 6)}-${phoneNumber.substring(6)}`;
  }

}