import { inject, Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
    name: 'FormattedDate'
})
export class DateFormatPipe implements PipeTransform {
    datePipe = inject(DatePipe);
    /**
     * @param value A date string or Date object.
     * @returns A formatted string in the format 'yyyy-MM-dd\nh:mm:ss a CT'.
     */
    transform(value: string | Date): string {
        if (!value) return '';

        const date = this.datePipe.transform(value, 'yyyy-MM-dd');
        const time = this.datePipe.transform(value, 'h:mm:ss a');

        return `${date}\n${time} CT`;
    }
}